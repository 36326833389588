<template>
<div>
   <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
  <v-card flat class="pa-3 mt-2">
    <v-card-text>
      <v-card-title class="text-no-wrap pt-1 ps-2"> EDIT COST RUPIAH</v-card-title>
      <form @submit.prevent="CreateData" ref="form">
        <v-row>
          <v-col cols="12" v-for="error in errors" :key="error">
            <v-alert color="error" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  <!-- {{ icons.mdiAlertOutline }} -->
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  <!-- <a href="javascript:void(0)" class="text-decoration-none warning--text">
                    <span class="text-sm">Resend Confirmation</span>
                  </a> -->
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field label="Cost Type" v-model="cost.jenis_biaya" dense outlined></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-header>Extention</v-header>
            <v-radio-group v-model="cost.perpanjangan_member" row>
              <v-radio label="Ya" value="Y" color="info"></v-radio>
              <v-radio label="Tidak" value="T" color="error"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col md="6" cols="12">
            <v-header>Price (Rp)</v-header>
            <currency-input dense outlined v-model="cost.harga" label="Price (Rp)" currency="IDR" locale="id" class="form-control"/></currency-input>

          </v-col>
          <v-col md="6" cols="12">
            <v-header>Active</v-header>
            <v-radio-group v-model="cost.aktif" row>
              <v-radio label="Ya" value="Y" color="info"></v-radio>
              <v-radio label="Tidak" value="T" color="error"></v-radio>
            </v-radio-group>
          </v-col>


          <v-col cols="12">
            <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

           <router-link :to="{ name: 'costrupiah-index' }">
              <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
            </router-link>
          </v-col>

        </v-row>
      </form>
    </v-card-text>
  </v-card>
  </div>
</template>
<script>
import { mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Cost Rupiah',
          disabled: false,
          href: '/admin/costrupiah',
        },
        {
          text: 'Update Cost Rupiah',
          disabled: true,
        },
      ],

      icons: {
        mdiSlashForward,
      },

      cost: {
        jenis_biaya: '',
        harga: '0',
        perpanjangan_member: 'Y',
        aktif: 'Y',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    this.GetAllData()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    GetAllData() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/biayas/${this.$route.params.id_biaya}/show`
      this.axios.get(uri).then(response => {
        this.cost = response.data.biaya
        // console.log(this.role.nama_role)
      })
    },
    CreateData(e) {
      // console.log('NAMA ROLE : ' + this.cost.nama_role)
      if (this.$data.cost.jenis_biaya.length === 0) {
        // this.errors.push('Cost Category required !')
        this.$swal.fire({
          title: 'Error',
          text: 'Cost Category required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.cost.harga.length === 0) {
        // this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Cost required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/biayas/${this.$route.params.id_biaya}`
        this.axios
          .post(uri, this.cost)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Cost rupiah updated successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
          .catch(e => {
            // console.log(e);
            this.$swal.fire({
              title: 'Error!',
              text: 'Cost rupiah failed to updated!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'costrupiah-index' })
        }, 3000)
      }
    },
  },
}
</script>
